import { useContext, useState } from 'react'
import { Col, Container, Dropdown, Row } from 'react-bootstrap'
import { OrderContext } from '../../contexts/OrderContext'
import { DateTime } from 'luxon'
import PaymentStatus from './PaymentStatus'
import RacquetAccordion from './RacquetAccordion'
import StringerAssignment from './StringerAssignment'
import BackButton from '../common/BackButton'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../routes'
import StatusControl from './StatusBadge'
// import RacquetTag from './labels/RaquetTag'
// import { PDFViewer } from '@react-pdf/renderer'
// import ThroatSticker from './labels/ThroatSticker'
import UserIcon from '../common/UserIcon'
import Icon from '../../icons/Icon'
import PrintLabelsButton from './PrintLabelsButton'
import ConfirmationModal from '../generic/ConfirmationModal'
import useDataService from '../../hooks/useDataService'
import TotalsRow from '../common/TotalsRow'

const AdminOrderView = () => {
  const navigate = useNavigate()
  const { order, setOrder } = useContext(OrderContext)
  const { apiCancelOrder } = useDataService()
  const [cancelModalShow, setCancelModalShow] = useState(false)

  return (
    <Container>
      <ConfirmationModal
        show={cancelModalShow}
        headerText='Cancel order?'
        bodyText='Are you sure you want to cancel this order?'
        actionButtonText='Confirm'
        handleCancel={() => setCancelModalShow(false)}
        handleConfirm={async () => {
          const o = await apiCancelOrder(order._id)
          setOrder(o)
          setCancelModalShow(false)
        }}
        danger
      />
      <Row className='justify-content-between align-items-center'>
        <Col xs='auto' className='row gx-3 align-items-center'>
          <Col xs='auto'>
            <BackButton onClick={() => navigate(ROUTES.Dashboard)} />
          </Col>
          <Col xs='auto'>
            <h2 className='mb-0'>#{order?.id}</h2>
          </Col>
          <Col xs='auto' className='align-self-end'>
            <p className='text-small'>{DateTime.fromISO(order.orderDate).toLocaleString(DateTime.DATETIME_SHORT)}</p>
          </Col>
        </Col>
        <Col xs='auto' className='row gx-1 justify-content-around align-items-stretch'>
          <Col xs='auto'>
            <StatusControl friendlyOrderId={order.id} orderId={order._id} status={order.status} update={setOrder} cancelled={order.cancelled} />
          </Col>
          <Col xs='auto'>
            <PrintLabelsButton />
          </Col>
          <Col xs='auto'>
            <Dropdown>
              <Dropdown.Toggle bsPrefix='dummy' variant='icon-wrapper'><Icon icon='ellipsis-vertical' /></Dropdown.Toggle>
              <Dropdown.Menu className='bg-white shadow-sm mt-1'>
                <Dropdown.Item
                  className='text-body'
                  disabled={order.status > 1}
                  onClick={() => navigate(`${ROUTES.Orders}/${order._id}/edit`)}
                >
                  <Icon icon='pencil' size={16} />
                  <span className='ps-1'>Edit order</span>
                </Dropdown.Item>
                <Dropdown.Item
                  className='text-danger'
                  disabled={order.status > 1}
                  onClick={() => setCancelModalShow(true)}
                >
                  <Icon icon='close' size={16} />
                  <span className='ps-1'>Cancel order</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Col>
      </Row>
      {/* <div>
        <PDFViewer>
          <ThroatSticker order={order}/>
        </PDFViewer>
      </div>
      <div>
        <PDFViewer style={{ width: '50%', height: '25vh' }}>
          <RacquetTag order={order}/>
        </PDFViewer>
      </div> */}
      <h4>Info</h4>
      <p className='text-small'>Scheduled pickup date & time</p>
      <p className='text-body pb-1'>{DateTime.fromISO(order.dueDate).toLocaleString(DateTime.DATETIME_SHORT)}</p>
      <p className='text-small'>Tournament</p>
      <p className='text-body'>{order.tournament.name}</p>
      <p className='text-small'>Customer</p>
      <Row className='align-items-center'>
        <Col xs='auto'>
          <UserIcon user={order.customer} onClick={() => navigate(`${ROUTES.Customers}/${order.customer._id}`)} />
        </Col>
        <Col xs='auto'>
          <p className='text-body'>{order.customer.firstName} {order.customer.lastName}</p>
          <p className='text-body'>{order.customer.email}</p>
        </Col>
      </Row>
      <hr />
      <PaymentStatus />
      <hr />
      <h4>Re-string</h4>
      <RacquetAccordion racquets={order.racquets} />

      <TotalsRow racquets={order.racquets} promoCode={order.promoCode} showPromoCode />

      <StringerAssignment />
    </Container>
  )
}

export default AdminOrderView
