import { Col, Container, Dropdown, Row } from 'react-bootstrap'
import { useContext, useState } from 'react'
import { SharedDataContext } from '../contexts/SharedDataContext'
import TournamentDropdown from '../components/common/TournamentDropdown'
import withTournamentSelectionRequired from '../components/generic/withTournamentSelectionRequired'
import StatsRow from '../components/charts/StatsRow'
import DueTimesChart from '../components/charts/DueTimesChart'
import DailyCompletedTotalsChart from '../components/charts/DailyCompletedTotalsChart'
import { find } from 'lodash'

const StringerWorkloadDashboard = () => {
  const { tournament } = useContext(SharedDataContext)
  const [selectedTournament, setSelectedTournament] = useState(tournament)
  const [selectedStringer, setSelectedStringer] = useState(tournament.stringers[0]?._id ?? 'All')

  return (
    <Container fluid className='mx-md-0 px-md-0'>
      <Row className='mx-0 px-1 pb-3 mb-3 border-bottom justify-content-between align-items-end'>
        <Col xs='auto'>
          <h3>Stringer Workload</h3>
          <p>Data is delayed by 5 minutes.</p>
        </Col>
      </Row>

      <Container>
        <Row className='gy-1 mb-3'>
          <Col xs={12} md={6}>
            <TournamentDropdown value={selectedTournament} handleSelect={(t) => {
              setSelectedTournament(t)
              setSelectedStringer(t.stringers[0]?._id ?? 'All')
            }}/>
          </Col>
          <Col xs={12} md={6}>
            <Dropdown
              onSelect={eventKey => setSelectedStringer(eventKey)}
              className='w-100'
            >
              <Dropdown.Toggle variant='input' className='w-100 justify-content-between'>
                {selectedStringer === 'All' ? 'All' : find(tournament.stringers, s => s._id === selectedStringer)?.fullName }
              </Dropdown.Toggle>

              <Dropdown.Menu className='w-100 bg-white shadow-sm'>
                {tournament.stringers.map(s => (
                  <Dropdown.Item key={s._id} eventKey={s._id}>{s.fullName}</Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </Container>
      <Container className='mb-4'>
        <StatsRow event={selectedTournament} stringer={selectedStringer}/>
      </Container>
      <Container>
        <Row className='bg-white rounded gx-0 gy-2 pb-2'>
          <Col xs={6}>
            <DueTimesChart event={selectedTournament} today stringer={selectedStringer} />
          </Col>
          <Col xs={6}>
            <DailyCompletedTotalsChart event={selectedTournament} stringer={selectedStringer}/>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default withTournamentSelectionRequired(StringerWorkloadDashboard)
