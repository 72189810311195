import { Col, Container, Row } from 'react-bootstrap'
import { useContext, useState } from 'react'
import { SharedDataContext } from '../contexts/SharedDataContext'
import TournamentDropdown from '../components/common/TournamentDropdown'
import withTournamentSelectionRequired from '../components/generic/withTournamentSelectionRequired'
import TopStringModels from '../components/charts/TopStringModels'
import StringBrandsChart from '../components/charts/StringBrandsChart'
import StringTypesChart from '../components/charts/StringTypesChart'
import StringPreferenceChart from '../components/charts/StringPreferencesChart'
import RacquetBrandsChart from '../components/charts/RacquetBrandsChart'
import TopRacquetModels from '../components/charts/TopRacquetModels'

const StringRacquetInsightsDashboard = () => {
  const { tournament } = useContext(SharedDataContext)
  const [selectedTournament, setSelectedTournament] = useState(tournament)

  return (
    <Container fluid className='mx-md-0 px-md-0'>
      <Row className='mx-0 px-1 pb-3 mb-3 border-bottom justify-content-between align-items-end'>
        <Col xs='auto'>
          <h3>String & Racquet Insights</h3>
          <p>Data is delayed by 5 minutes.</p>
        </Col>
      </Row>
      <Container>
        <Row className='gy-1 mb-3'>
          <Col xs={12} md={6}>
            <TournamentDropdown value={selectedTournament} handleSelect={(t) => setSelectedTournament(t)} />
          </Col>
        </Row>
      </Container>

      <Container>
        <Row className='bg-white rounded gx-0 gy-2 pb-2 mb-4'>
          <Col xs={6}>
            <StringBrandsChart event={selectedTournament} />
          </Col>
          <Col xs={6}>
            <TopStringModels event={selectedTournament}/>
          </Col>
          <Col xs={6}>
            <StringTypesChart event={selectedTournament} />
          </Col>
          <Col xs={6}>
            <StringPreferenceChart event={selectedTournament} />
          </Col>
        </Row>
        <Row className='bg-white rounded gx-0 gy-2 pb-2 mb-4'>
          <Col xs={6}>
            <RacquetBrandsChart event={selectedTournament} />
          </Col>
          <Col xs={6}>
            <TopRacquetModels event={selectedTournament} />
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default withTournamentSelectionRequired(StringRacquetInsightsDashboard)
