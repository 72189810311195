import { Document, Image, Page, Text, View } from '@react-pdf/renderer'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'

const propTypes = {
  order: PropTypes.object.isRequired
}

const ThroatSticker = ({ order }) => {
  return (
    <Document>
      {order.racquets.map(r => {
        const res = []
        for (let i = 0; i < r.quantity; i++) {
          res.push(
            <Page key={r._id} size={{ width: 135, height: 25 }} style={{ fontSize: 10, fontFamily: 'Helvetica' }}>
              <View style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
                <View style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                  <Text>{r.mains.tension.toFixed(2)}/{r.crosses.tension.toFixed(2)} {r.measure}</Text>
                  <Text>Due: {DateTime.fromISO(order.dueDate).toLocaleString(DateTime.DATE_SHORT)}</Text>
                </View>
                <View style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                  <Image src='https://tournaments-stringr-bucket.s3.ap-southeast-2.amazonaws.com/ao-logo.png' style={{ height: '18px' }}/>
                </View>
              </View>
            </Page>
          )
        }
        return res
      }).flat()}
    </Document>
  )
}

ThroatSticker.propTypes = propTypes

export default ThroatSticker
